import React from 'react'
import {GetStaticProps} from 'next'
import dynamic from 'next/dynamic'

import {getBrandCodeFromUrlOrCookie} from '@daedalus/core/src/_web/brand/utils'
import {
  brands,
  defaultBrand,
  getBrandByCode
} from '@daedalus/core/src/brand/config'
import {Brand} from '@daedalus/core/src/brand/types'

export interface PageProps {
  brand: Brand
}

/**
 * Note that all app providers are loaded client side to ease the migration from CRA.
 * To move to SSR this will need to be decoupled.
 */
const AppProviders = dynamic(() => import('../AppProviders'), {
  ssr: false
})

const AppRoutes = dynamic(() => import('../AppRoutes'), {
  ssr: false
})

const Page = (props: PageProps) => {
  let {brand} = props

  // We're abusing how getStaticPaths work, and sometimes when
  // doing client side navigation brand will be lost.
  if (!brand && typeof window !== 'undefined') {
    // eslint-disable-next-line fp/no-mutation
    brand = getBrandByCode(getBrandCodeFromUrlOrCookie(window._env.APP_ENV))
  }

  return (
    <>
      <AppProviders>
        <AppRoutes />
      </AppProviders>

      {/* Note: we are rendering this at build time so don't use other conditions besides page props! (until we move to server render) */}
      {brand?.code === defaultBrand.code ? (
        <div id="splash_screen">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            alt="Vio.com"
            className="logo"
            src="/static/vio-logo-compact.svg"
          />
          <span className="loader"></span>
        </div>
      ) : null}
    </>
  )
}

export default Page

/**
 * Gets called at build time
 * It generates all the page slugs we want pages for (ie. one per brand)
 */
export async function getStaticPaths() {
  const paths = Object.values(brands).map(brand => {
    return {
      params: {
        slug: brand.code === defaultBrand.code ? [] : [`index-${brand.code}`]
      }
    }
  })

  return {paths, fallback: false} // { fallback: false } means other routes should 404.
}

/**
 * Also gets called at build time
 * It generates the pageProps for each page based on the params from ^
 * These will be available to `_app.tsx` and also the route itself
 */
export const getStaticProps: GetStaticProps<PageProps> = async ({params}) => {
  const brandCode = params.slug?.length
    ? params.slug[0].split('-')[1]
    : defaultBrand.code

  const brand = getBrandByCode(brandCode)
  if (!brand) throw new Error(`No brand config found for ${brandCode}`)

  return {props: {brand}}
}
